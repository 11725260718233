<template>
  <v-layout>
    <GenericModal
      v-model="showValidator"
      :has-close="false"
      :title="$t('patient.validation')"
      persistent
      max-width="600">
      <v-layout wrap class="mt-6">
        <v-flex xs12>
          <v-identify-document
            data-test="patient-input-document-type"
            dense
            filled
            :id-document="documentId"
            :id-document-type="documentType"
            use-required
            @input="setIdDocument"
          />
          <v-text-field
            v-model="birthDate"
            v-mask="'##/##/####'"
            data-test="eprescription-validation_patient-birthdate"
            filled
            outlined
            dense
            :label="`${$t('patient.birthdate')}*`"
            append-inner-icon="mdi-event"
          />
        </v-flex>
      </v-layout>
      <template v-slot:actions>
        <DButton
          :disabled="!birthDate || !documentId"
          :primary="true"
          :loading="isSending"
          @click.native="sendValidation"
        >Enviar</DButton
        >
      </template>
    </GenericModal>
  </v-layout>
</template>

<script>
import {checkHipUrl, getHeader, hipUrl} from '@/config/config';
import * as pdfjsLib from 'pdfjs-dist';
import GenericModal from "@/components/atoms/GenericModal.vue";
import DButton from "@/components/atoms/DButton.vue";
import VIdentifyDocument from "@/modules/VuetifyIdentityDocument/components/VIdentifyDocument.vue";
import axios from "axios";

export default {
  name: 'Hip',
  components: {VIdentifyDocument, DButton, GenericModal},
  data() {
    return {
      showValidator: true,
      isSending: false,
      birthDateMenu: false,
      data: '',
      showIframe: false,
      documentType: null,
      documentId: null,
      birthDate: null,

    };
  },
  computed: {
    dateFormatted() {
      return this.birthDate
        ? this.$moment(this.birthDate, 'DD/mm/YYYY').format('DD/mm/YYYY')
        : null;
    },
  },
  methods: {
    setIdDocument(data) {
      this.documentId = data.idDocument;
      this.documentType = data.idDocumentType;
    },
    async sendValidation() {
      this.isSending = true;

      const code = this.$route?.params?.code;
      if (!code){
        this.$toast.error(this.$t('hiring.apiError'));
        return;
      }

      try {
        const { data } =
        (await axios.post(`${checkHipUrl}/${code}`, {
          id_document: this.documentId,
          birthdate: this.$moment(this.birthDate, 'DD/mm/YYYY').format('YYYY-mm-DD'),
        }, {
          headers: getHeader()
        })) || {};

        if (!data) {
          this.$toast.error(this.$t('hiring.apiError'));
          return;
        }

        await this.getData(data.temp_token);
      } catch (e) {
        await this.getData();
      } finally {
        this.isSending = false;
      }
    },
    async getData(tempToken) {
      const code = this.$route?.params?.code;
      if (!code) return;

      const url = new URL(`${hipUrl}/${code}`);
      const params = {
        temp_token: tempToken,
        id_document: this.documentId,
        birthdate: this.$moment(this.birthDate, 'DD/mm/YYYY').format('YYYY-mm-DD'),
      };
      url.search = new URLSearchParams(params).toString();

      const onlyPrint = this.$route?.params?.pathMatch === 'print';

      try {
        const { data } =
        (await axios.get(tempToken ? url.toString() : `${hipUrl}/${code}`, {
          headers: getHeader(),
          responseType: 'arraybuffer'
        })) || {};

        if (!data) return;

        await this.showFileDownload(data, code, onlyPrint);
      } catch (e) {
        this.$toast.error(this.$t('hiring.apiError'));
      }
    },
    async showFileDownload(data, code, onlyPrint) {
      const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
      if (!onlyPrint) {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.setAttribute('download', `${this.$t('recipe.recipe')}_${code}.pdf`);
        link.click();
      }

      pdfjsLib.GlobalWorkerOptions.workerSrc = await import('pdfjs-dist/build/pdf.worker.entry');
      const loadingTask = pdfjsLib.getDocument({ data: data });

      const outputScale = window.devicePixelRatio || 1;

      loadingTask.promise.then(pdf => {
        for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
          pdf.getPage(pageNumber).then(function(page) {
            const desiredWidth = document.getElementById('pageContainer').clientWidth;
            const viewport = page.getViewport({ scale: 1 });

            const scale = desiredWidth / viewport.width;
            const scaledViewport = page.getViewport({ scale: scale });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            document.getElementById('pageContainer').appendChild(canvas);
            document.getElementById('pageContainer').appendChild(document.createElement('br'));

            canvas.height = scaledViewport.height * outputScale;
            canvas.width = scaledViewport.width * outputScale;
            canvas.style.width = Math.floor(scaledViewport.width) + 'px';
            canvas.style.height = Math.floor(scaledViewport.height) + 'px';

            const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

            const renderContext = {
              canvasContext: context,
              transform: transform,
              viewport: scaledViewport,
            };

            page.render(renderContext);
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hip--fullscreen {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

#pageContainer {
  margin: auto;
  width: 80%;
}

@media only screen and (max-width: 600px) {
  #pageContainer {
    margin: auto;
    width: 100%;
  }
}

#pageContainer canvas {
  display: inline-block;
  width: 100%;
}

div.page {
  display: inline-block;
}

canvas {
  width: 100%;
  height: 100%;
}
</style>
